<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-detail">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/work_order_list' }">工单列表</el-breadcrumb-item>
					<el-breadcrumb-item>工单详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div v-loading="loading">
				<template v-if="orderDetail">
					<div class="order-status">
						<h4>
							工单状态	：
							<span class="ns-text-color">{{orderDetail.order_status_name}}</span>
						</h4>

						<div class="operation" v-if="orderDetail.order_action && orderDetail.order_action.length > 0">
							<el-button type="primary" size="mini" :plain="true" v-for="(operationItem, operationIndex) in orderDetail.order_action"
							 :key="operationIndex" @click="operation(operationItem.action)">
								{{ operationItem.title }}
							</el-button>
						</div>
					</div>

					<div class="order-info">
						<h4>工单信息</h4>
						<ul>
							<li>服务类型：{{orderDetail.work_type_name}}</li>
							<li>服务方式：{{orderDetail.service_type_name}}</li>
							<li v-if="orderDetail.service_type==1">上门地址：{{orderDetail.full_address}}{{orderDetail.address}}</li>
							<li>联系方式：{{orderDetail.mobile}}</li>
							<li>联系人：{{orderDetail.name}}</li>
							<li>预约时间：{{orderDetail.appoint_day}} {{orderDetail.appoint_time}}</li>
						</ul>
					</div>
					
					<div class="order-info">
						<h4>门店信息</h4>
						<ul>
							<li>店家名称：{{orderDetail.store_name}}</li>
							<li>店家地址：{{orderDetail.store_full_address}}{{orderDetail.store_address}}</li>
							<li>联系方式：{{orderDetail.store_telephone}}</li>
						</ul>
					</div>
					
					<div class="take-delivery-info" v-if="orderDetail.worker_id">
						<h4>工程师信息</h4>
						<ul>
							<li>安装师傅：{{orderDetail.worker_username}}</li>
							<li>联系方式：{{orderDetail.worker_mobile}}</li>
						</ul>
					</div>
					<!-- 发票信息 -->
					<div class="take-delivery-info" v-if="orderDetail.worker_id">
						<h4>工程师记录</h4>
						<ul>
							<li>问题描述：{{orderDetail.worker_desc ? orderDetail.worker_desc : '无'}}</li>
							<li>解决方案：{{orderDetail.worker_solution ? orderDetail.worker_solution : '无'}}</li>
							<li>服务备注：{{orderDetail.worker_remark ? orderDetail.worker_remark : '无'}}</li>
						</ul>
					</div>
					
					<div class="take-delivery-info">
						<h4>核销信息</h4>
						<ul>
							<li>服务卡名称：{{orderDetail.service_card_name ? orderDetail.service_card_name : '暂未使用服务卡'}}</li>
							<span v-if="!orderDetail.service_card_name" @click="openServiceCard()">点击添加</span>
						</ul>
					</div>
					<h4>商品信息</h4>
					<nav>
						<li>商品信息</li>
						<li>单价</li>
						<li>数量</li>
					</nav>

					<!-- 订单项·商品 -->
					<div class="list">
						<ul class="item" >
							<li>
								<div class="img-wrap" @click="$router.pushToTab('/sku-' + orderDetail.relate_store_sku_id)">
									<img :src="$img(orderDetail.relate_sku_image, { size: 'mid' })" @error="imageError()" />
								</div>
								<div class="info-wrap">
									<h5 @click="$router.pushToTab('/sku-' + orderDetail.relate_store_sku_id)">{{ orderDetail.relate_sku_name }}</h5>
									<!-- <span>规格：规格值</span> -->
								</div>
							</li>
							<li>
								<span>￥{{ orderDetail.relate_price }}</span>
							</li>
							<li>
								<span>{{ orderDetail.relate_num }}</span>
							</li>
							
						</ul>
					</div>
					
					<h4 v-if="orderDetail.inventory_goods_list && orderDetail.inventory_goods_list.length">购物清单</h4>
					<nav v-if="orderDetail.inventory_goods_list && orderDetail.inventory_goods_list.length">
						<li>商品信息</li>
						<li>单价</li>
						<li>数量</li>
						<li>小计</li>
					</nav>
					
					<!-- 订单项·商品 -->
					<div class="list" v-if="orderDetail.inventory_goods_list && orderDetail.inventory_goods_list.length">
						<ul class="item" v-for="(gItem,gIndex) in orderDetail.inventory_goods_list" :key="gIndex">
							<li>
								<div class="img-wrap" @click="$router.pushToTab('/sku-' + gItem.store_sku_id)">
									<img :src="$img(gItem.sku_image, { size: 'mid' })" @error="imageError(gIndex)" />
								</div>
								<div class="info-wrap">
									<h5 @click="$router.pushToTab('/sku-' + gItem.store_sku_id)">{{ gItem.sku_name }}</h5>
								</div>
							</li>
							<li>
								<span>￥{{ gItem.price }}</span>
							</li>
							<li>
								<span>{{ gItem.num }}</span>
							</li>
							<li>
								<span>{{ gItem.goods_money }}</span>
							</li>
						</ul>
						<!-- 购物清单总计 -->
						<div class="total_price">清单总金额：{{orderDetail.inventory_pay_money}}</div>
					</div>
				</template>
			</div>
		</el-card>
		
		<el-dialog title="我的服务卡" :visible.sync="service_card_info" width="33%"  class="my_card">
			<div class="card_list">
				<div class="card_item" v-for="(item,index) in orderDetail.service_card_data" :key="index">
					<img :src="$img(item.sku_image,{size:'mid'})" alt="">
					<div class="card_name">{{item.sku_name}}</div>
					<div class="spec_name">{{item.verify_num}}次卡</div>
					<div class="num">剩余{{item.remain_verify_num}}次</div>
					<div class="use_btn" @click="$router.pushToTab('/member/detail_service_card?order_id='+item.order_id)">使用</div>
				</div>
			</div>
			<div class="bottom">
				<span class="purchase" @click="$router.pushToTab('/cardList?goods_id='+orderDetail.relate_goods_id+'&sale_store_id='+orderDetail.store_id)">暂无服务卡，去购买</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { workOrderDetail ,cancelWorkOrder ,deleteWorkOrder } from '@/api/order/work_order'
	export default {
		name: 'order_detail',
		components: {},
		data: () => {
			return {
				loading: true,
				yes: true,
				work_order_id:'',
				orderDetail:{},
				service_card_info:false
			};
		},
		created() {
			if(this.$route.query.work_order_id) this.work_order_id = this.$route.query.work_order_id;
			this.getWorkOrderDetail();
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		methods: {
			
			openServiceCard(){
				this.service_card_info = true;
			},
			operation(mode){
				switch (mode){
					case 'orderCancel':
						//取消工单
						this.$confirm("您确定要取消该工单吗？", "提示", {
						    confirmButtonText: "确定",
						    cancelButtonText: "取消",
						    type: "warning"
						})
						.then(() => {
							cancelWorkOrder({
								work_order_id:this.work_order_id
							})
							.then(res=>{
								if(res.code>=0){
									this.$message('取消成功');
									setTimeout(()=>{
										this.$router.push({path:'/member/work_order_list'})
									},500)
								}
							})
							.catch(err=>{})
						})
						.catch(()=>{
						})
						break;
					case 'delete':
						//删除工单
						this.$confirm("您确定要删除该工单吗？", "提示", {
						    confirmButtonText: "确定",
						    cancelButtonText: "取消",
						    type: "warning"
						})
						.then(() => {
							deleteWorkOrder({
								work_order_id:this.work_order_id
							})
							.then(res=>{
								if(res.code>=0){
									this.$message('删除成功');
									setTimeout(()=>{
										this.$router.push({path:'/member/work_order_list'})
									},500)
								}
							})
							.catch(err=>{})
						})
						.catch(()=>{
						})
						break;
					case 'modifyAddress':
						//修改地址
						this.$router.push({path:'/member/edit_worker_address',query:{work_order_id:this.work_order_id,back:'/member/work_order_detail?work_order_id='+this.work_order_id}});
						break;
					case 'modifyAppointTime':
						//修改上门时间
						this.$router.push({path:'/member/edit_worker_time',query:{work_order_id:this.work_order_id,back:'/member/work_order_detail?work_order_id='+this.work_order_id}});
						break;
					case 'evaluate':
						//工单评价
						this.$router.push({path:'/member/evaluate_worker',query:{work_order_id:this.work_order_id,back:'/member/work_order_detail?work_order_id='+this.work_order_id}});
						break;
					default:
						break;
				}
			},
			getWorkOrderDetail(){
				workOrderDetail({
					work_order_id:this.work_order_id
				})
				.then(res=>{
					if(res.code>=0){
						this.orderDetail = res.data;
						this.loading = false;
					}
				})
				.catch(err=>{})
			},
			imageError(index='') {
				if(index){
					this.orderDetail.inventory_goods_list[index].sku_image = this.defaultGoodsImage;
				}else{
					this.orderDetail.relate_sku_image = this.defaultGoodsImage;
				}
			}
		},
		filters: {
			
		}
	};
</script>
<style lang="scss">
	.my_card{
		.el-dialog{
		}
		.el-dialog__header{
			padding: 28px 32px 0 !important;
			margin-bottom: 28px;
			.el-dialog__title{
				color: #222;
				font-size: 18px;
				line-height: 24px;
				font-weight: 500;
			}
		}
		.el-dialog__body{
			padding: 0 16px 36px 32px !important;
			.bottom{
				text-align: center;
				padding-right: 32px;
				.purchase{
					padding: 8px 28px;
					background-color: #0055FF;
					color: #fff;
					font-size: 16px;
					line-height: 22px;
					border-radius: 4px;
					cursor: pointer;
				}
			}
			
			.card_list{
				&::-webkit-scrollbar {
				    width: 10px;
					background: rgba(0,0,0,0.1);
				}
				&::-webkit-scrollbar-thumb {
				    background: rgba(0,0,0,0.2);
				}
				padding-right: 16px;
				overflow: auto;
				height: 380px;
				margin-bottom: 20px;
				.card_item{
					margin-bottom: 18px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					&:last-child{
						margin-bottom: 0;
					}
					img{
						width: 48px;
						height: 48px;
						display: block;
						border-radius: 4px;
						margin-right: 30px;
					}
					.card_name{
						color: #333;
						font-size: 14px;
						line-height: 20px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						margin-right: 20px;
						flex: 1;
					}
					.spec_name,.num{
						color: #666;
						font-size: 14px;
						line-height: 20px;
						margin-right: 64px;
					}
					.num{
						margin-right: 28px;
					}
					.use_btn{
						color: #fff;
						line-height: 20px;
						font-size: 14px;
						padding: 5px 18px;
						border-radius: 20px;
						background-color: #0055FF;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	.el-button + .el-button{
		margin-left: 0;
	}
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.order-detail {
		.order-status {
			background-color: #fff;
			margin-bottom: 20px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			.go-pay {
				p {
					display: inline-block;
					vertical-align: middle;

					span {
						font-weight: bold;
						color: $base-color;
						font-size: 18px;
					}
				}
			}

			.operation {
				margin-top: 10px;
				.el-button{
					margin-right: 15px;
				}
			}
		}

		.order-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
		}

		.take-delivery-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
				span{
					color: #0055FF;
					cursor: pointer;
				}
			}
		}

		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 50%;

					&.no-operation {
						width: 60%;
					}
				}

				&:nth-child(2) {
					width: 15%;
				}

				&:nth-child(3) {
					width: 10%;
				}

				&:nth-child(4) {
					width: 15%;
				}

				&:nth-child(5) {
					width: 10%;
				}
			}
		}

		.list {
			border-bottom: 1px solid #eeeeee;
			margin-bottom: 25px;
			.item {
				background-color: #fff;
				padding: 10px 0;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 50%;
						line-height: inherit;

						&.no-operation {
							width: 60%;
						}

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 15%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 10%;
					}
				}
			}
		}

		// 总计
		.total {
			padding: 20px;
			background-color: #fff;
			text-align: right;

			li {
				span {
					width: 150px;
					display: inline-block;
				}

				&.pay-money {
					font-weight: bold;

					span {
						color: $base-color;
						font-size: 16px;
						vertical-align: middle;
					}
				}
			}
		}
	}
.total_price{
	text-align: right;
	padding-right: 10%;
}

</style>
